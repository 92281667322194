import {taskcreateConstants} from "../constants";

const initialState ={
    task:[],
    question: {}
};
export function taskcreate(state = initialState,action){
    switch(action.type){
        case taskcreateConstants.ADDTASK_RQUEST:
            return{
                ...state,
                status:"ADDINGTASK_REQUEST"
            };
        case taskcreateConstants.ADDTASK_SUCCESS:
            return{
                ...state,
                status:"ADDINGTASK_SUCCESS"
            };
        case taskcreateConstants.ADDTASK_ERROR:
            return{
                ...state,
                status:"ADDINGTASK_ERROR",
                task: action.error
            };
        case taskcreateConstants.QUESTION_REQUEST:
            return{
                ...state,
                status:"QUESTION_REQUEST"
            };
        case taskcreateConstants.QUESTION_SUCCESS:
            return{
                ...state,
                status:"QUESTION_SUCCESS",
                question: action.message
            };
        case taskcreateConstants.QUESTION_ERROR:
            return{
                ...state,
                status:"QUESTION_ERROR",
                question: action.message
            };
        default:
            return state;
    }
    
}