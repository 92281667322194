export const utils = {
    ValidateMobileNumber,
    validate,
    getParameterByName
}

function ValidateMobileNumber (mobile) {
    let pattern = "[+]{1}[0-9]{12}";
    return mobile.match(pattern);
}

function getParameterByName(name, url) {
    
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));

}

function validate(regex, value){
    let re = new RegExp(regex);
    return re.test(value);
}