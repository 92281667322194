import React, { Component } from "react";
import "../../screens/home/index.css";

class Input extends Component {
    render () {
        const { onChange = () => null, value, type, onClick = () => null } = this.props;
        
        return(
            <div className="mp-input-container">
                <form className="c-form" action="">
                    <div className="mp-input">
                        <label className="c-form__label" for="textValue">
                            <input
                                type={type}
                                id="textValue"
                                name="textValue"
                                className="c-form__input"
                                placeholder=" "
                                value={value}
                                onChange={ e => onChange(e)}
                                required/>

                            <label className="mp-form-next" role="button"  onClick={onClick}>
                                <span className="c-form__nextIcon" ></span>
                            </label>
                            <b className="mp-form-border"></b>
                        </label>
                    </div>
                </form>
            </div>
        );
    }
}

export default Input;