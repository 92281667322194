import React,{Component} from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import WhatsApp from "../../images/WhatsApp.png";
import Store from "../../reducers";
import "../../screens/home/index.css";
import appinfo from "../../app.json";

export default class Banner extends Component{
  
    renderContent = (content) => {
        let widget=Store.getState().widget.widgetconfig;
        return (
        <div>
        {typeof widget.header!=="undefined" &&   <h1 style={{color:widget.primary_color.value}}>
                {widget.header.value}
            </h1>}
            {typeof widget.header==="undefined" &&   <h1 style={{color:widget.primary_color.value}}>
                 Contact us
                 
            </h1>}
            { content }
              <div style={{display:"flex",flexDirection: "row", textAlign: "center"}}>
                    <div style={{display:"flex",flexDirection: "row", textAlign: "center",margin:"32px auto 32px auto"}}>
                      {widget.phone_show.value===true &&  <a href= {"tel:" + widget.Phone.value} className="bold" style={{textDecoration:"none",color:widget.primarytextcolor.value,padding:8}}>
                        {/* or <i style={{}} class="material-icons">call</i>  */}
                        or call {widget.Phone.value}
                        </a>}
                      {widget.whatsapp_show.value===true && <a href={"https://wa.me/"+widget.Phone.value.replace('+','')} target ="_blank">
                            <img src={WhatsApp} style={{height:35, paddingLeft: 0,padding:"0px 8px"}} alt={"whatsapp"}/>
                        </a>}
                    </div>
              </div>
        </div>
        )
    }
    renderContentlocal = (content) => {
      return (
      <div>
          <h1 style={{color:appinfo.primary_color.value}}>
              {appinfo.header.value}
          </h1>
          { content }
            <div style={{display:"flex",flexDirection: "row", textAlign: "center"}}>
                  <div style={{display:"flex",flexDirection: "row", textAlign: "center",margin:"32px auto 32px auto"}}>
                    {appinfo.phone_show.value===true &&  <a href= {"tel:" + appinfo.Phone.value} className="bold" style={{textDecoration:"none",color:appinfo.priimaryTextColor.value,padding:8}}>
                      {/* or <i style={{}} class="material-icons">call</i>  */}
                      or call {appinfo.Phone.value}
                      </a>}
                    {appinfo.whatsapp_show.value===true && <a href={"https://wa.me/"+appinfo.Phone.value.replace('+','')} target ="_blank">
                          <img src={WhatsApp} style={{height:35, paddingLeft: 0,padding:"0px 8px"}} alt={"whatsapp"}/>
                      </a>}
                  </div>
            </div>
      </div>
      )
  }

  render(){
    let widget=Store.getState().widget.widgetconfig;
    let widget1=Store.getState().widget;
      return (
        <div>
            {widget1.status!=="GETTING_WIDGETJSON_ERROR" && <div className="mp-card" style={{background:widget.bg_color.value,borderRadius:widget.border_radius.value}}>
                     {this.renderContent(this.props.children)}
            </div>}
            {widget1.status==="GETTING_WIDGETJSON_ERROR" && <div className="mp-card" style={{background:appinfo.bg_color.value,borderRadius:appinfo.border_radius.value}}>
               {this.renderContentlocal(this.props.children)}
           </div>}
           </div>
      );
    }
}