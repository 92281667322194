import React,{Component} from "react";
import { connect } from "react-redux";
import {withRouter} from "react-router-dom";
import { Button } from 'react-bootstrap';
import "./index.css";
import "./index.scss";
import {
    Banner,
    Input,
} from "../../component";
import Login from "../login";
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';
import {    taskcreateActions, widgetActions } from "../../actions";
import appinfo from "../../app.json";
import notyf from "../../component/notfy";

class Home extends Component {
    constructor(){
        super();
        this.state = {
            response: {},
            price_option: null,
            questions: {},
            amount: 0,
            q_id: -1,
            form: -1,
            mobileflow:  false,
            task: {},
            processing: false,
            lat: "",
            lon: "",
            widgetconfig:{},
            widgetadded:false
        }
        this.ClickTask = this.ClickTask.bind(this);
    }

    componentWillMount() {
        const { dispatch, taskcreate, endpoint,widget } = this.props;
        if(widget.status==="GETTING_WIDGETJSON_SUCCESS" ){
            this.setState({widgetconfig:widget.widgetconfig,widgetadded:true})
        } else dispatch(widgetActions.getjson());
        if(taskcreate.status === "QUESTION_SUCCESS"){
            this.setState({ questions : taskcreate.question.message});
        }
    }

    componentWillReceiveProps(nxtProps) {
        const { taskcreate, authentication, dispatch,widget,endpoint } = this.props;
        let hasTaskPropsChanged = nxtProps.taskcreate.status !== taskcreate.status;
        let hasWidgetPropsChanged = nxtProps.widget.status !== widget.status; 
      
        if(widget.status==="GETTING_WIDGETJSON_SUCCESS" ){
            this.setState({widgetconfig:widget.widgetconfig,widgetadded:true});    
        }
        if(widget.status==="GETTING_WIDGETJSON_REQUEST" && hasWidgetPropsChanged&& taskcreate.status !== "QUESTION_SUCCESS"){
            dispatch(taskcreateActions.GetQuestion(this.state.q_id, endpoint.params.form));  
        }
       if(widget.status==="GETTING_WIDGETJSON_ERROR"){
            notyf.open({type:"warning",message:"Sorry,We Couldn't update your configuration data"})
            this.setState({widgetconfig:appinfo,widgetadded:true});
        }
        
        if(hasTaskPropsChanged){
            if(nxtProps.taskcreate.status === "QUESTION_SUCCESS"){
                this.setState({ questions : nxtProps.taskcreate.question.message});
            }
            else if(nxtProps.taskcreate.status === "ADDINGTASK_ERROR"){
                notyf.open({type:"error",message: nxtProps.taskcreate.task.message});
            }
            if(nxtProps.taskcreate.status === "QUESTION_ERROR"){
              
                notyf.open({type:"error",message:"Something Went Wrong"});
            }
        }
      
           
   

        let hasPropsChanged = authentication.status !== nxtProps.authentication.status ;

        if(hasPropsChanged){
            if(["SIGNUP_SUCCESS", "LOGIN_SUCCESS"].includes(nxtProps.authentication.status)){
                document.getElementById("finish").checked = true;
                dispatch(taskcreateActions.Addtask(this.state.task));
            }

        }

    }
    renderLocation = (option) => {
        const {widget}=this.props;
        const {widgetconfig}=this.state;
        let data = {lat: "", lon: ""};
        navigator.geolocation.getCurrentPosition(function print(response){
            data["lat"] = response.coords.latitude;
            data["lon"] = response.coords.longitude;
        }, function error(){
            alert("You have denied Location permission to serve. Please Allow to experience fine service.");
        });
        return (<div className="questions-btn">
                        <input className="questions" style={{alignContent:'center',backgroundColor:widgetconfig.button_color.value,color:widgetconfig.secondary_color.value,borderRadius:widgetconfig.border_radius.value,borderDecoration:"none"}} 
                            type="button" value={"Next"} onClick={()=>this.ClickTask(option[0], "location", data)}/>
                    </div>);
    }


    radiobutton = (options) => {
        const {widgetconfig}=this.state;
        return (
            <div>
                {   options.map ( (opt,index) => {
                       return ( 
                       <div  className="radio">
                            <input  className="radio-2" type="radio" name="radio" id={"radio"+index}
                                    value={opt.name}
                                    onClick={()=> this.ClickTask(opt, "radio") }
                                    />
                            <label className="radio-label" style={{color:widgetconfig.secondarytextcolor.value}} for={"radio"+index}> { opt.name } </label>
                       </div> );
                    })
                }
            </div>
        );
    }

    button=(options)=>{
        const {widget}=this.props;
        const {widgetconfig}=this.state;
        return (
            <div>
                {
                    options.map(opt =>{
                        return(
                            <div className="questions-btn">
                                <input className="questions" style={{alignContent:'center',backgroundColor:widgetconfig.button_color.value,color: widgetconfig.secondary_color.value,borderRadius:widgetconfig.border_radius.value,borderDecoration:"none"}} type="button" id="exampleButton1" value={opt.name} onClick={()=>this.ClickTask(opt,"button")}/>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    renderField = (questions) => {
        if(questions.option[0].type === "navButton")
            return (<div  style={{textAlign: "center"}}>
                        {this.button(questions.option)} 
                    </div>);
        else if(questions.option[0].type === "button") 
            return ( <div  style={{textAlign: "center"}}>
                        {this.radiobutton(questions.option)} 
                    </div>);
        else if(questions.option[0].type === "location")
                      return ( <div  style={{textAlign: "center"}}>
                              {this.renderLocation(questions.option)} 
                          </div>);
        else if(questions.option[0].type === "text")
            return (<div style={{textAlign: "center"}}>
                        <Input 
                            type="text"
                            value={this.state.textValue}
                            onChange={(e) => this.setState({textValue: e.target.value})}
                            onClick={() => this.ClickTask(questions.option[0], "text")}
                        />
                    </div>);
        else if(questions.option[0].type === "datetime")
            return (<div style={{textAlign: "center"}}>
                        <Input 
                            type="date"
                            value={this.state.textValue}
                            onChange={(e) => this.setState({textValue: e.target.value})}
                            onClick={() => this.ClickTask(questions.option[0], "text")}
                        />
                    </div>);
    }

    ClickTask = (option, type, data = {}) => {
        const { dispatch, authentication, endpoint } = this.props;
        const { questions, form } = this.state;
        let resp = this.state.response ;
        let question = questions.question;
        let lat = "";
        let lon = "";
        if(this.state.q_id !== null){
            if(type === "radio") resp[question.title] = option.name;
            else if(type === "location"){
                lat = data.lat;
                lon = data.lon;
            }
            else resp[question.title] = this.state.textValue;
        }
        let location = (lat != "" && lon != "")||(this.state.lat != "" && this.state.lon != "");
        this.setState(
            {   q_id            : option.callback, 
                response        : resp,
                price_option    : option.online_payment === true ?  option.id : this.state.price_option,
                amount          : option.online_payment === true ? this.state.amount + option.amount : this.state.amount,
                textValue       : "", 
                lat             : lat === "" ? this.state.lat : lat,
                lon             : lon  === "" ? this.state.lon : lon
            }
        ); 
        if( option.callback > 0 ) dispatch(taskcreateActions.GetQuestion(option.callback, endpoint.params.form));
        else {
            let task = {
                    task_details : Object.keys(resp).length != 0 ? JSON.stringify(resp) : "", 
                    price_option: this.state.price_option,
                    location_provided: location,
                    form_id:parseInt(endpoint.params.form),
                };
            if(location){
                task["lat"] = lat != "" ? lat : this.state.lat;
                task["long"] = lon != "" ? lon : this.state.lon;
            }
            if(authentication.user === undefined)
                this.setState({ mobileflow: true, task: task});
            else dispatch(taskcreateActions.Addtask(task));
        }
    }

    goBack = () => {
        const { questions, response } = this.state;
        const{ dispatch, endpoint } = this.props;
        let resp = response;
        if(![undefined, "", null].includes(response[questions.question.title])){
            delete resp[questions.question.title];
            this.setState({ response: resp});
        }
        dispatch(taskcreateActions.GetQuestion(questions.question.prev, endpoint.params.form));
    }

    render(){
        document.title = "";
        const { questions,widgetconfig } = this.state;
        const { taskcreate,widget } = this.props;

        return (
            <div>
              {(widget.status ==="GETTING_WIDGETJSON_SUCCESS" || widget.status ==="GETTING_WIDGETJSON_ERROR")  && this.state.widgetadded&&
              <div style={{fontFamily:widgetconfig.font_family.value}}>
               <Banner>
                    {
                        !this.state.mobileflow && taskcreate.status === "QUESTION_SUCCESS" && 
                        (   <div className="container">
                            <div style={{display: "flex",flexDirection:"row"}}>
                                { questions.question.prev &&
                                    <i  className="material-icons" 
                                        style={{cursor:"pointer", marginLeft: 20,marginTop: 20,color:widgetconfig.button_color.value}} 
                                        onClick={this.goBack}
                                >arrow_back</i>
                               
                                }
                                <p style={{paddingLeft: 20, width: "100%", paddingRight: 20, textAlign: "center",color: widgetconfig.primarytextcolor.value}}>
                                    {questions.question.title}
                                </p>
                            </div>
                              { this.renderField(questions) }
                            </div>
                        )
                    }
                    {
                        taskcreate.status ==="ADDINGTASK_SUCCESS"&&
                        <div style={{textAlign: "center"}}>
                            <h4 style={{color:widgetconfig.primary_color.value}}>
                                { typeof(widgetconfig.greetings) !== "undefined" ? widgetconfig.greetings.value : "THANK YOU" }</h4>
                        </div>
                    }
                    {
                        this.state.mobileflow &&
                         <Login />
                    }
                </Banner>
                </div> }

            </div>
        )
    }
}

function mapStateToProps(state) {
    const { taskcreate, authentication, endpoint ,widget} = state;
    return {
        taskcreate,
        authentication,
        endpoint,
        widget
    };
  }
export default withRouter(connect(mapStateToProps)(Home));
