import React from "react";
import { connect } from "react-redux";
import { endpointActions ,widgetActions} from "../../actions";
import {withRouter} from "react-router-dom";
import "../home/index.css";
import { Loader } from "../../component";


class RootLoader extends React.Component {
  constructor() {
    super();
    this.state = {
      error: false,
      invalid: false
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;

    const urlParams = new URLSearchParams(window.location.search);
    let data = {};
    for( const entry of urlParams.entries())
      data[entry[0]] = entry[1];
    dispatch(endpointActions.setUrlParams(data));
    dispatch(endpointActions.ping());
  }


  componentDidUpdate(prevProps) {
    const { history,dispatch } =this.props;
    let newProps = this.props;
    let propsChanged = prevProps.endpoint.status !== newProps.endpoint.status;


    if (newProps.endpoint.status === "PING_SUCCESS" && propsChanged) {
      let formid = parseInt(newProps.endpoint.params.form);
      let clientid = newProps.endpoint.params.id;
      let shouldNot = ["", null, undefined, NaN];
      if(!shouldNot.includes(formid) && !shouldNot.includes(clientid)){
        history.push("/home");}
      else this.setState({ invalid: true });
    }
    else if(newProps.endpoint.status === "PING_ERROR" && propsChanged){
      this.setState({error: true});
    }
  }

  render() {
    if(this.state.error)
      return <div style={{textAlign: "center", marginTop: 30}}>Error Occurred in Server</div>;
    else if(this.state.invalid)
      return <div style={{textAlign: "center", marginTop: 30}}>Invalid link</div>;
    else
      return <Loader />;
  }
}

function mapStateToProps(state) {
  const { endpoint } = state;
  return {
      endpoint
  };
}

export default withRouter(connect(mapStateToProps)(RootLoader));
