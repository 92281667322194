import React from 'react';
import {Provider} from 'react-redux';
import {BrowserRouter,Route,Switch} from "react-router-dom";
import {persistStore} from 'redux-persist';
import { PersistGate } from 'redux-persist/es/integration/react';
import Home from "./screens/home";
import RootLoader from "./screens/root_loader";
import Ads from "./screens/ads";

import './App.css';
// import 'bootstrap/dist/css/bootstrap.min.css';

const App = ({store}) => {

  return(
    <Provider store={store}>
    <PersistGate loading={null} persistor={persistStore(store)} >
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={RootLoader} />
          <Route exact path="/home" component={Home}/>
          <Route exact path="/ads" component={Ads} />
        </Switch>
      </BrowserRouter>
    </PersistGate>
    </Provider>
  )
}

export default App;