import React from 'react';
import Lottie from 'react-lottie';
import { connect }from "react-redux";
import { withRouter } from "react-router-dom";
import { Widget, 
        addResponseMessage, 
        toggleWidget,
        setQuickButtons, 
        toggleMsgLoader, 
        addUserMessage 
    } from 'react-chat-widget';
import { endpointActions, 
        taskcreateActions, 
        authenticationActions 
    } from "../../actions";

import animationData from '../../component/lotties/28306-support-chat.json';
import notyf from "../../component/notfy";
import logo from "./logo192.png";
import flow from "./flow.json";
import 'react-chat-widget/lib/styles.css';
import "./index.css";
import { utils } from '../../utils';
import { parsePhoneNumber, parsePhoneNumberWithError, ParseError } from 'libphonenumber-js';

 
class Ads extends React.Component {

    constructor(props){
        super();
        this.state = {
            mobile: false,
            userAnswers : {},
            error: false,
            q_id: -1,
            questionFlow: true,
            is_Available: false,
            step: "0",
            credentials: {mobile: "", name: "", otp: "", mail: "", country_code: ""},
            errorCount : 0
        }
    }

    componentDidMount(){
        const { dispatch } = this.props; 
        const urlParams = new URLSearchParams(window.location.search);
        let data = {};
        for( const entry of urlParams.entries())
            data[entry[0]] = entry[1];
        dispatch(endpointActions.setUrlParams(data));
        if([undefined, null, NaN, ""].includes(data.id) || [undefined, null, NaN, ""].includes(data.form))
            this.setState({ error: true }
                , () => alert("Sorry! Invalid Link")
            );
        else{
            addResponseMessage('Lets Chat!');
            toggleWidget();
            this.setState({ mobile: window.screen.width < 560});
            dispatch(taskcreateActions.GetQuestion(-1, data.form));
        }

    }

    componentDidUpdate(prevProps){
        const { taskcreate, authentication, dispatch } = this.props;
        const { step, is_Available, userAnswers } = this.state;
        let taskPropsChanged = prevProps.taskcreate.status !== taskcreate.status;
        let authPropsChanged = prevProps.authentication.status !== authentication.status;
        if(taskPropsChanged){
            if(taskcreate.status === "QUESTION_SUCCESS")
                this.renderQuestion(taskcreate.question.message);
            if(taskcreate.status === "ADDINGTASK_SUCCESS"){
                addResponseMessage("Thanks! Your Response has been received. We will contact you soon")
                setQuickButtons([]);
            }
            if(taskcreate.status.indexOf("ERROR") > -1)
                notyf.open({type:"error", message: "SOMETHING WENT WRONG!!"});
            toggleMsgLoader();
        }
        if(authPropsChanged){
            if(authentication.status === "ISAVAILABLE_SUCCESS"){
                this.setState({ is_Available: !authentication.available.is_available });
                if(!authentication.available.is_available)  this.forOtp();
                else    this.nextCall(flow[step].next["not_available"], false);
            }else if(authentication.status === "SENDINGOTP_SUCCESS"){
                notyf.open({ type: "success", message: "OTP SUCCESSFULLY SENT TO EMAIL/MOBILE NUMBER"});
                let nxt = flow[step].next[is_Available ? "available": "not_available"];
                if(nxt !== null)   this.nextCall(nxt, false);
            }
            if(["SIGNUP_SUCCESS", "LOGIN_SUCCESS"].includes(authentication.status))        // Checks Whether the User is Signed/Logged In.
                this.submitTask(); 
            if(authentication.status.indexOf("ERROR") > -1)
                notyf.open({type:"error", message: authentication.error.message || "SOMETHING WENT WRONG!!"});   
            toggleMsgLoader();
        }
    }

    submitTask = () => {
        const { userAnswers } = this.state;
        const { dispatch,endpoint  } = this.props;

        let task = {
            task_details : Object.keys(userAnswers).length != 0 ? JSON.stringify(userAnswers) : "", 
            price_option: null,
            location_provided: false,
            form_id:parseInt(endpoint.params.form)
        };
        dispatch(taskcreateActions.Addtask(task));  
    }

    isAvailable = (from = "", value, next, questionFlow) => {
        const { dispatch } = this.props;
        const { mobile } = this.state.credentials;
        try{
            let pN = parsePhoneNumberWithError(value);
            if(from === "mobile" && pN.countryCallingCode === "91" && pN.isValid() && pN.nationalNumber.length === 10){
                    let payload ={ phone_number: value };
                    dispatch(authenticationActions.isAvailable(payload));
            }
            else if(from === "mobile" && pN.countryCallingCode === "91" )
                    addResponseMessage("Invalid value");
            else if(from === "mail" && parsePhoneNumber(mobile).countryCallingCode === "91"){
                    let payload ={ mail_id : value };
                    dispatch(authenticationActions.isAvailable(payload));
            }else this.nextCall(next, questionFlow);
        }catch(e){
            if(e instanceof ParseError) addResponseMessage(e.message);
            else addResponseMessage("Unable to Parse Valid Phone Number");
        }
    }

    forOtp = () => {
        const { dispatch } = this.props;
        const { mobile, mail } = this.state.credentials;
        let payload ={ };
        if(parsePhoneNumber(mobile).countryCallingCode === "91") 
            payload["phone_number"] = mobile ;
        else payload["mail"] = mail;
        dispatch(authenticationActions.sendOtp(payload));
    }

    button(qAndOptions = []){
        let q = qAndOptions.option.map( opt => { 
            return {label: opt.name, value: {q: qAndOptions.question.title, opt}}
        });
        setQuickButtons(q);
    }

    renderQuestion = (qAndOptions) => {
        addResponseMessage(qAndOptions.question.title);

        if(["navButton", "button"].includes(qAndOptions.option[0].type))
            this.button(qAndOptions);

    }

    renderToggler = (handleToggle) => {
        return( !this.state.mobile ?
            <div className="toggleHandler" onClick={handleToggle}>
                <i  className="material-icons icon">chat</i>
            </div> : <div/>
        );
    }

    questionSubmit = (otp) => {
        const { dispatch } = this.props;
        const { mail, mobile, name } = this.state.credentials;
        let payload = { otp };

        if(!this.state.is_Available){
            payload["name"] = name;
            payload["country_code"] = "+"+parsePhoneNumber(mobile).countryCallingCode
            payload["mail"] = mail;
            payload["phone_number"] = mobile;
            dispatch(authenticationActions.signup(payload));
        } else {
            if( parsePhoneNumber(mobile).countryCallingCode === "91") 
                payload["phone_number"] = mobile;
            else payload["mail"] = mail;
            dispatch(authenticationActions.login(payload));
        }
    }

    nextCall = (callback = null, questionFlow = true, value) => {
        const { endpoint, dispatch, authentication } = this.props;
        if(questionFlow && callback > 0)                                                    //Checks Whether the option has next question to call.
            dispatch(taskcreateActions.GetQuestion(callback, endpoint.params.form))         // If Yes, Call the API to get next Question.
        else if(questionFlow && callback === null )                                          // If Null , Then it tends to get Submit the answers
            if(["SIGNUP_SUCCESS", "LOGIN_SUCCESS"].includes(authentication.status))       // Checks Whether the User is Signed/Logged In.
                this.submitTask();                                                          // If Yes, submits the Question to the backend.
            else{
                addResponseMessage(flow["0"].title);
                this.setState({ questionFlow: false });                                     //Else, Trigger the Login/SignUp Flow.
            }
        else if(callback === null)
            this.questionSubmit(value);
        else{
            this.setState({ step: callback+"" });
            addResponseMessage(flow[callback].title);
            if(flow[callback].next["available"] === null)  this.renderQuestion(flow["otp"]);
        }
    }
    
    setUserAnswer = (key, value) => {
        let userAnswers = this.state.userAnswers;
        userAnswers[key] = value;
        this.setState({    userAnswers     })
    }

    handleNewUserMessage = (NewUserMessage) => {
        const { taskcreate } = this.props;
        const { questionFlow, step, is_Available, credentials } = this.state;

        if(this.handleSubmit(NewUserMessage)){
            if(questionFlow){
                this.setUserAnswer(taskcreate.question.message.question.title, NewUserMessage);
                this.nextCall(taskcreate.question.message.option[0].callback);
            }else {
                let cred = credentials;
                let next = flow[step].next[is_Available ? "available" : "not_available"];
                cred[flow[step].key] = NewUserMessage;
                this.setState({  crendentials: cred });
                if(parseInt(step) < 2)  this.isAvailable(flow[step].key, NewUserMessage, next, questionFlow);
                else    this.nextCall(next, questionFlow, NewUserMessage);
            }
        }else{
            addResponseMessage("Invalid Entry!!! Enter again");
            this.setState({ error : true });
        }
    }

    handleQuickButtonClicked = (QuickButton) => {
        const { questionFlow, errorCount } = this.state;
        
        if(questionFlow){
            this.setUserAnswer(QuickButton.q, QuickButton.opt["name"])
            addUserMessage(QuickButton.opt["name"]);
            this.setState({ q_id:  QuickButton.opt.callback })
            this.nextCall(QuickButton.opt.callback);
            setQuickButtons([]);
        }else {
            if(errorCount < 3){
                this.setState({ errorCount: errorCount+1 });
                if(QuickButton.opt.value === "OTP") this.forOtp();
                else if(QuickButton.opt.value === "RESET"){
                    setQuickButtons([]);
                    this.nextCall(QuickButton.opt.resetValue, false);
                }
            }
            else {
                setQuickButtons([]);
                addResponseMessage("Exceeded Maximum limit");
            }
        }
    }

    handleSubmit = (Submit) => {
        const { questionFlow, step } = this.state;
        
        if(questionFlow)    return Submit !== "" ;
        else    return utils.validate(flow[step].regex, Submit ) ;

    }

    render(){
        const { mobile } = this.state;

        return (
            <div className="App" style={{display: "flex"}}>
                { !mobile &&
                    <div style={{float: "left", margin: "8%"}}>
                        <Lottie 
                            options={{
                                loop: true,
                                autoplay: true,
                                animationData: animationData,
                                rendererSettings: { preserveAspectRatio: "xMidYMid slice" }
                            }}
                            height={400}
                            width={500}
                        />
                    </div> 
                }
                <Widget 
                    title="Moonproduct"
                    titleAvatar={logo}
                    subtitle=""
                    senderPlaceHolder={"Your Text Reply Here"}
                    fullScreenMode={true}
                    // launcher={handleToggle => this.renderToggler(handleToggle)}
                    showCloseButton={false}
                    handleNewUserMessage={this.handleNewUserMessage}
                    handleQuickButtonClicked={this.handleQuickButtonClicked}
                    handleSubmit={this.handleSubmit}
                />
            </div>
        );
    }
}

function mapStateToProps(state){
    const { endpoint, taskcreate, authentication } = state;

    return { endpoint, taskcreate, authentication };
}

const connectedAds = connect(mapStateToProps)(Ads);
export default withRouter(connectedAds);