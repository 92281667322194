import {widgetConstants} from "../constants";
import {widgetService} from "../services";

export const widgetActions ={
    getjson
}

function getjson(){
    return dispatch =>{
        dispatch(request());
        widgetService.getjson().then(
            res => dispatch(success(res)),error => dispatch(failure(error))
        );
    };
    function request(){
        return {type:widgetConstants.GETTING_WIDGETJSON_REQUEST};
    }
    function success(res){
        return {type:widgetConstants.GETTING_WIDGETJSON_SUCCESS,res};
    }
    function failure(error){
        return {type:widgetConstants.GETTING_WIDGETJSON_ERROR,error}
    }
}