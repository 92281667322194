import {endpointService} from "../services";
import {endpointConstants} from "../constants";

export const endpointActions ={
    ping,
    setUrlParams
};

function ping(){
    return dispatch =>{
        dispatch(request());
        endpointService.ping()
        .then(res => dispatch(success(res)),err =>dispatch(error(err)));
    };
    function request(){
        return {type: endpointConstants.PING_REQUEST};
    }
    function success(res){
        return{type:endpointConstants.PING_SUCCESS,res};
    }
    function error(err){
        return{type:endpointConstants.PING_ERROR, err};
    }
}

function setUrlParams(params = {}){
    return {type: endpointConstants.PARAMS_SUCCESS, params};
}