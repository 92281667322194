import {widgetConstants} from "../constants";

const initialState ={
     
    widgetconfig:[]

};

export function widget(state =initialState,action){
    switch(action.type){
        case widgetConstants.GETTING_WIDGETJSON_REQUEST:
            return {
                ...state,
                status:"GETTING_WIDGETJSON_REQUEST"
            };
        case widgetConstants.GETTING_WIDGETJSON_SUCCESS:
            return {
                ...state,
                status:"GETTING_WIDGETJSON_SUCCESS",
                widgetconfig:action.res
            }
        case widgetConstants.GETTING_WIDGETJSON_ERROR:
            return {
                ...state,
                status:"GETTING_WIDGETJSON_ERROR",
                widgetconfig:action.error
            }
        default:
            return state;
    }
}